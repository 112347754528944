.app__header{
    display: flex;
}

.spoon__img{
    width: 10%;
    height: 12px;
}
.subheading_title{
    font-family: var(--subtitle-font-name);
    color: var(--heading-color);
    margin-top: 4rem;
}

.app__header-h1{
    font-family: var(--font-name);
    font-size: 90px;
    color: var(--primary-color);
    word-spacing: 0.6em;
    letter-spacing:  0.07em;
    text-transform: uppercase;
    line-height: 117px;
}

.app__wrapper_img{
   width: 100%;
   justify-content: center;
   align-items: center;
}

.app__wrapper_img img{
    width: 80%; 
    margin: 6rem 0;
    border-radius: 0.5rem;
}

.p__opensans{
    color: var(--text-color);
    margin: 2rem 0;
}


.custom__buttton{
    padding: 0.5rem 1.5rem !important;
    background: var(--secondary-color) !important;
    color: #fff !important;
    font-size: 1.1rem !important;
    border-radius: 1rem;
}


.app__specialMenu{
    flex-direction: column;
    background-color: beige;
}

.app__specialMenu-title{
    margin-bottom: 2rem;
    text-align: center;
}






.app__aboutus{
    position: relative;
   
} 
.app__abouts-overlay {
    position: absolute;
    inset: 0;
}

.app__abouts-overlay img{
    width: 391px;
    height: 415px;
    z-index: 0;
}

.app__specialMenu-menu{
    width: 100%;

    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}

.app__aboutus-content { 
width: 100%;
z-index: 2;
}

.app__aboutus-content_about{
 flex: 1;
 display: flex;
 justify-content: flex-end;
 align-items: flex-end;
 flex-direction: column;
 text-align: right;
}

.app__aboutus-content_history{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
   }

.app__aboutus-content_knife{
    margin: 2rem 4rem;
}

.app__aboutus-content_knife img{
    height:197px;
}

.app__aboutus-content_about p{
  margin: 2rem 0;
  color: green;
}

.app__aboutus-content_history p{
    margin: 2rem 0;
    color: green;
}







@media only screen and (max-width: 992px)  {
    .app__wrapper{
        display: flex;
        flex-direction: column;
       }
       .app__wrapper_img img{
        width: 100%; 
        height: 21rem;
    }
}

@media only screen and (max-width:768px) {
    .app__header-h1{
       font-size: 4.3rem;
   }

   .app__wrapper{
    display: flex;
    flex-direction: column;
   }

   .app__wrapper_img img{
    width: 100%; 
    height: 21rem;
}
.app__header-h1{
    font-family: var(--font-name);
    font-size: 3rem;
    line-height: 5rem;
    color: var(--primary-color);
}

.p__opensans{
    margin: 0 0 2rem 0;
}
}
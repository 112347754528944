/* achievement */
.about__achievements{
    margin-top: 3rem;
    width: 100%;
}

.about__header{
    margin-bottom: 2.5rem;
}

.about__img{
    height: 90rem;
    width: 50%;
}
.about__achievements-container{
    display: grid;
    grid-template-columns: 71% 65%;
    justify-content: center;
    align-items: center;
    gap: 9rem;
}

.about__achievements-left img{
    width: 100%;
    border-radius: 1rem;
}

.about__achievements-right p{
    margin: 1.6rem 0 2rem;
    color: var(--text-color);
}

.img_normal{
    height: 36rem;
    border-radius: 1rem;
}

.achievements__cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.achievement__card{
    background: var(--color-bg1);
    padding: 1.6rem;
    border-radius: 1rem;
    text-align: center;
    transition: var(--transition);
}

.achievement__card:hover{
    background: var(--color-bg2);
    box-shadow: 0 3rem 3rem rgb(0, 0, 0, 0.3);
}

.achievement__icon{
    background: var(--color-danger);
    padding: 0.6rem;
    border-radius: 1rem;
    display: inline-block;
    margin-bottom: 2rem;
    font-size: 1.6rem;
}

.achievement__card:nth-child(2) 
.achievement__icon{
    background: var(--color-success);
}

.achievement__card:nth-child(3) 
.achievement__icon{
    background: gold;
}

.social__links span li{
    text-decoration: none;
    color: var(--heading--color);
    font-size: 1.2rem;    
}

.achievement__card  p{
    margin-top: 1rem;
}


/*end of achivement */



/* start of Team */
.team{
    background: var(--color-bg1);
    box-shadow: inset 0 0 3rem rgb(0, 0, 0, 0.5);
}

.team__container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
}

 .team__member__image img{
        width: 100%;
        height:  100%;
        border: 1px solid var(--secondary-color);
        object-fit: cover;
    }


.team__member{
    background-color: rgba(17, 77, 49, 0.788);
    border: 1px solid transparent;
    border-radius: 1.5rem;
    transition: var(--transition);
    position: relative;
    overflow: hidden;
}

.team__member:hover{
    background: transparent;
    border-color: var(--color-primary);
}

.team__member__image img {
    filter: saturate(0);
}

.team__member:hover img{
    filter: saturate(1);
}

.team__member-info * {
    text-align: center;
    margin-top: 1.4rem;
}

.team__member-info p {
    color: #000;
}

.team__member-socials {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -100%;
    display: flex;
    flex-direction: column;
    background: rgb(10, 10, 223);
    border-radius: 1rem 0 0 1rem;
    box-shadow: -2rem 0 2rem rgb(0, 0, 0, 0.3);
    transition: var(--transition);
}


.team__member:hover 
.team__member-socials{
    right: 0;
}

.team__member-socials a{
    padding: 1rem;
    
}

.team__member-socials span i{
    color: gold;
    font-size: 1.5rem;
}

.about__achievements-right__title{
    color: var(--secondary-color);
    font-family: var(--subtitle-font-name);

}



/* media quries for tablet */
@media only screen and (max-width:992px) {
   .about__achievements{
    margin-top: 2rem;
   }

   .about__achievements-container{
    grid-template-columns: 1fr;
    gap: 4rem;
   }

   .about__achievements-left{
    width: 80%;
    margin: 0 auto;
   }


   .team__container{
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
   }

   .team__member{
    padding: 0rem;
   }
}

/* End of tablet mode */





/* media quries for sm */
@media only screen and (max-width:768px)  {

    .achievements__cards{
        grid-template-columns: 1fr 1fr;
        gap: 0.7rem;
    }

    .team__container{
        grid-template-columns: 1fr 1fr;
        gap: 0.7rem;
    }

  

    .team__member{
        
        padding: 0rem;
       }
    .team__member__image img{
        width: 100%;
        height: 14rem;
    }

    .team__member p{
        margin-bottom: 1.5rem;
    }
}


@media only screen and (max-width: 576px){
    .team__container{
        grid-template-columns: 1fr 1fr;
        gap: 0.7rem;
    }

    .team__member{
        padding: 0;
    }

    .team__member__image img{
        height: 50%;
    }

    

    .team__member p{
        margin-bottom: 1.5rem;
    }
}
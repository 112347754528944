.header {
    width: 100%;
    height: 80px;
    line-height: 80px;
}

.logo img {
    width: 9rem;
    height: 5rem;
    padding-bottom: 13px;
}

.menu {
    width: auto;
    min-width: 12rem;
    height: 100%;
    color: rgb(251, 255, 251);
    font-weight: 450;
    letter-spacing: 0.1rem;
    font-size: 1.2rem;
    display: grid;
    place-items: center;
    padding: 0 1rem;
    background: none;
    box-shadow: none;
    gap: 2rem; /* Add gap between menu items */
}

.nav__item a {
    text-decoration: none;
    color: var(--heading-color);
    font-weight: 500;
    font-size: 1.1rem;
}

.nav__item a:hover {
    color: inherit;
}

.nav__item a.active__link {
    color: var(--secondary-color);
}

.mobile__menu {
    font-size: 1.3rem;
    color: var(--heading-color);
    display: none; /* Hide the mobile menu button by default */
    background: transparent;
    cursor: pointer;
}

.sticky__header {
    width: 100%;
    height: 80px;
    line-height: 80px;
    background-color: #fff;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 3px 3px 8px -3px #ddd;
    z-index: 999;
}

.nav__btns-boundary {
    height: 40px;
    border-left: solid 2px goldenrod;
}

.navigation.show__menu {
    display: block;
}

.close__menu__btn {
    display: none;
}
@media only screen and (max-width: 1024px) {
    .menu {
        width: 100%;
        min-width: unset;
        padding: 0;
    }

    .menu li {
        height: auto;
    }

    .menu li a {
        padding: 0.5rem 1rem; /* Adjust padding */
        text-align: left; /* Align text to the left */
    }

    .mobile__menu {
        display: inline-block; /* Display the mobile menu button at 1024px */
    }
}


@media only screen and (max-width:992px) {
    .logo img {
        width: 20%;
    }

    .close__menu__btn {
        display: inline-block; /* Change display to inline-block to make the button visible */
        font-size: 1.8rem;
        cursor: pointer;
        color: var(--heading-color); /* Add a color for the button */
    }
    

    .nav__btns-boundary {
        display: none;
    }

    .show__menu {
        display: block;
    }

    .mobile__menu {
        display: inline-block;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .close__menu__btn {
        display: none;
    }

    .navigation {
        position: fixed;
        top: 5rem;
        right: 4%;
        display: none;
    }

    .menu {
        height: fit-content;
        width: 40rem;
        flex-direction: column;
        gap: 0.66;
    }
    .nav__item a.active__link{
        color: var(--secondary-color);
    }
    .menu li {
        width: 100%;
        height: 5.8rem;
        background: rgba(0, 0, 0, 0.664);
        animation: animateNavItems 400ms linear forwards;
        transform-origin: top right;
        opacity: 0;
    }

    .menu li:nth-child(2) {
        animation-delay: 200ms;
    }

    .menu li:nth-child(3) {
        animation-delay: 400ms;
    }

    .menu li:nth-child(4) {
        animation-delay: 600ms;
    }

    @keyframes animateNavItems {
        0% {
            transform: rotateZ(-90deg) rotateX(90deg) scale(0.1);
        }

        100% {
            transform: rotateZ(0) rotateX(0) scale(1);
            opacity: 1;
        }
    }

    .menu li a {
        background: rgba(0, 0, 0, 0.349);
        box-shadow: -4rem 6rem 10rem rgba(0, 0, 0, 0.664);
        width: 100%;
        height: 100%;
        color: rgb(251, 255, 251);
        font-weight: 450;
        letter-spacing: 0.1rem;
        font-size: 1.2rem;
        display: grid;
        place-items: center;
    }

    .menu li a:hover {
        background: rgba(1, 54, 19, 0.555);
        color: #fff;
    }
}

@media only screen and (max-width:768px) {
    .logo img {
        width: 45%;
    }

    .menu {
        width: auto;
        min-width: 12rem;
        height: 100%;
        color: rgb(251, 255, 251);
        font-weight: 450;
        letter-spacing: 0.1rem;
        font-size: 1.2rem;
        display: grid;
        place-items: center;
        padding: 0 1rem;
        background: none; /* Remove the background color */
        box-shadow: none; /* Remove the box shadow */
        gap: 1rem; /* Adjust gap between menu items */
    }

    .close__menu__btn {
        display: inline-block;
        font-size: 1.8rem;
        cursor: pointer;
        color: var(--heading-color);
    }

    .menu li a {
        background: none;
        box-shadow: none;
        flex: 1; /* Make menu items flexible to take available space */
        text-align: center;
        padding: 0.5rem;
        transition: background-color 0.3s ease; /* Add transition for hover effect */
    }

    .menu li a:hover {
        background-color: rgba(1, 54, 19, 0.555);
        color: #fff;
    }

    .nav__btns-boundary {
        display: none;
    }

    .show__menu {
        display: block;
    }
}
